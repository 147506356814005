import React from "react"
import PropTypes from "prop-types"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Fab from "@material-ui/core/Fab"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import Zoom from "@material-ui/core/Zoom"
import css from "./Scrollbar.module.css"
import { Link, onClick } from "gatsby"

// import { AnchorLink } from "gatsby-plugin-anchor-links"

// import { HashLink as Link } from 'react-router-hash-link';

const handleLink = linkID => {
  const anchor = document.querySelector("#" + linkID)
  console.log("handle link called,", anchor)
  if (anchor) {
    anchor.scrollIntoView({ behavior: "smooth", block: "center" })
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

function ScrollTop(props) {
  const { children, window } = props
  const classes = useStyles()
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    )

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  )
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

export default function BackToTop(props) {
  return (
    <React.Fragment>
      <AppBar
        style={{
          background: "#ab987a",
          textAlign: "center",
          textcolor: "#0f1626",
          height: "40px",
        }}
      >
        {/* <Toolbar classes={{ root: css.scrollBarText }}> */}
        <div classname={css.scrollBarText}>
          <p className={css.anchorLinks}>
            <Link
              style={{
                background: "#ab987a",
                textAlign: "center",
                textcolor: "#0f1626",
                height: "40px",
                width: "40px",
              }}
              to="/HeaderLighting/"
            >
              {" "}
              Lighting |
            </Link>
            <Link
              style={{
                background: "#ab987a",
                textAlign: "center",
                textcolor: "#0f1626",
                height: "40px",
                width: "40px",
              }}
              to="/HeaderFurniture/"
            >
              {" "}
              Furniture |
            </Link>
            <Link
              style={{
                background: "#ab987a",
                textAlign: "center",
                textcolor: "#0f1626",
                height: "40px",
                width: "40px",
              }}
              to="/HeaderAccessories/"
            >
              {" "}
              Accessories |
            </Link>
            <Link
              style={{
                background: "#ab987a",
                textAlign: "center",
                spacing: "20px",
                textcolor: "#0f1626",
                height: "40px",
                width: "40px",
              }}
              to="/HeaderProjects/"
            >
              {" "}
              Projects |
            </Link>
            <Link
              style={{
                background: "#ab987a",
                textAlign: "center",
                textcolor: "#0f1626",
                height: "40px",
                width: "40px",
              }}
              to="/HeaderAboutUs/"
            >
              {" "}
              About Us
            </Link>
          </p>
          {/* <p
            className={css.anchorLinks}
            onClick={() => handleLink("techprojects")}
          >
            Lighting
          </p>
          <p
            className={css.anchorLinks}
            onClick={() => handleLink("personalprojects")}
          >
            Furniture
          </p>
          <p className={css.anchorLinks} onClick={() => handleLink("mystory")}>
            Accessories
          </p>
          <p
            className={css.anchorLinks}
            onClick={() => handleLink("collaborate")}
          >
            Projects
          </p>

          <p className={css.anchorLinks} onClick={() => handleLink("socials")}>
            About Us
          </p> */}
        </div>
        {/* </Toolbar> */}
      </AppBar>
      <Toolbar id="back-to-top-anchor" />

      <ScrollTop {...props}>
        <Fab
          style={{
            background: "#ab987a",
            textAlign: "center",
            textcolor: "#0f1626",
            height: "40px",
            width: "40px",
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  )
}

{
  /* <Link to="/page-2/">Lighting</Link>
<Link to="/page-3/">Furniture</Link>
<Link to="/page-4/">Accessories</Link>
<Link to="/page-5/">Projects</Link>
<Link to="/page-6/">About Us</Link>
<Link to="/page-7/">Alfresco..</Link>
<Link to="/page-8/">Collaborate...</Link> */
}
