import React from "react"
import css from "./HexagonProjects.module.css"
import Header from "../Header/index"
import Murals from "../Projects/Murals"
import Projects from "../Projects/Projects"
import Chairs from "../Projects/Chairs"
import Lighting from "../Projects/Lighting"
import Stools from "../Projects/Stools"
import Tables from "../Projects/Tables"
import Sculptures from "../Projects/Sculptures"
import ToDos from "../Projects/StorageGallery"
import Merch from "../Projects/Merch"
import Tech from "../Projects/Tech"
import Amazon from "../Projects/Amazon"
import CNCCutting from "../Projects/CNCCutting"
import Particle from "../Particles/index"
import urtisanlogo from "../../images/urtisanlogo.png"

function HexagonProject() {
  return (
    <div className={css.hexagon}>
      <Particle />

      <br></br>
      <br></br>

      <div>
        <img className={css.Applogo} src={urtisanlogo} alt="logo" />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <Header />
      <section>
        <article>
          <figure>
            <h2>Maquinas</h2>
            <Murals />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1501420896719-ad7fe0ee297e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
          />
        </article>
        <article>
          <figure>
            <h2>Software</h2>
            <Projects />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1532511332889-6db654bf6529?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1953&q=80"
          />
        </article>
        <article>
          <figure>
            <h2>Accesorios</h2>
            <Chairs />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1520554704788-a6a2e7854290?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
          />
        </article>
        <article>
          <figure>
            <h2>Videos</h2>
            <Lighting />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1516562309708-05f3b2b2c238?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80"
          />
        </article>
        <article>
          <figure>
            <h2>Galería de Fotos</h2>
            <Stools />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1514443031610-8c063c7a9822?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
          />
        </article>
        <article>
          <figure>
            <h2>Nuestros Clientes</h2>
            <Tables />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1463082459669-fd1ca1692fea?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>Manuales</h2>
            <Sculptures />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1547609434-b732edfee020?ixlib=rb-1.2.1&auto=format&fit=crop&w=1927&q=80"
          />
        </article>
        <article>
          <figure>
            <h2>Contáctenos</h2>
            <ToDos />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1497219055242-93359eeed651?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        {/* <article>
          <figure>
            <h2>Merch</h2>
            <Merch />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1520372561567-bac27b0e5fa1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>Tech</h2>
            <Tech />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1487266659293-c4762f375955?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
          />
        </article>
        <article>
          <figure>
            <h2>Amazon</h2>
            <Amazon />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1517958189574-a9423e47b514?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1949&q=80"
          />
        </article>
        <article>
          <figure>
            <h2>CNC Cutting</h2>
            <CNCCutting />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1492541641671-bd75cf01a094?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
          />
        </article> */}
      </section>

      <svg width="0" height="0">
        <defs>
          <clipPath id="hexagono" clipPathUnits="objectBoundingBox">
            <polygon points=".25 0, .75 0, 1 .5, .75 1, .25 1, 0 .5" />
          </clipPath>
        </defs>
      </svg>
      <br></br>
      <br></br>
      <br></br>
    </div>
  )
}

export default HexagonProject
