import React from "react"
import { Link, onClick } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HexagonProject from "../components/HexagonProjects"
import Scrollbar from "../components/Scrollbar/scrollbar"
import Particles from "../components/Particles/index"

const IndexPage = () => (
  <Layout>
    <Scrollbar />

    <SEO title="Home" />

    <HexagonProject />

    <br></br>
  </Layout>
)

export default IndexPage
