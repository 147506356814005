import React, { useContext, useEffect, useState } from "react"
import css from "./Lighting.module.css"
import { Link, onClick } from "gatsby"

function Lighting() {
  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }
  }, [])

  return (
    <div>
      <a
        className={css.seeLiveProject4}
        target="_blank"
        rel="noopener noreferrer"
        href="https://github.com/impactyogi"
      >
        Shop
      </a>
      <br></br>
      <Link
        style={{
          textAlign: "center",
          textcolor: "#0f1626",
          height: "40px",
          width: "40px",
        }}
        to="/LightingGallery/"
      >
        {" "}
        Gallery
      </Link>
    </div>
  )
}

export default Lighting
