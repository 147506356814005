import React from "react";
import css from "./Header.module.css";
import Particle from "../Particles";

function Header() {
  return (
    <div className={css.header}>
      <Particle />
    </div>
  );
}

export default Header;
